.presentation-body {
  min-height: 100vh;
    /* fallback for old browsers */
  /* background: rgba(106, 17, 203, 0.8); */

  /* Chrome 10-25, Safari 5.1-6 */
  /* background: -webkit-linear-gradient(to right, rgba(210, 209, 211, 0.8), rgba(189, 189, 190, 0.8)); */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background: linear-gradient(to right, rgba(173, 172, 173, 0.8), rgba(212, 212, 212, 0.8)); */
}

.presentation-console {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:10px;
}

.home-button {
  float: right;
  margin: 20px !important;
}

.question, .image {
  margin: 1vw 0;
  padding: 1vw;
  border: 3px solid #ccc;
  width: 80%;
  text-align: center;
}

.image img {
  max-width: 100%;
}

.navigation button {
  margin: 5px;
}