.captain-body {
  min-height: 100vh;
}

.captain-header {
  display: flex; 
  justify-content: center; 
  align-items: center;
  padding-left: 0px !important;
}

.captain-sider {
  width: 14vw !important;
  max-width: 14vw !important;
  min-width: 14vw !important;
  align-items: center;
}

.captain-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh;
}
.captain-console {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh;
}

.question, .image, .answer-section {
  margin: 1vw 0;
  padding: 1vw;
  border: 3px solid #ccc;
  width: 80%;
  text-align: center;
}

.captain-game-info {
  color: pink;
  align-items: center;
  margin-bottom: 1.5vw;
  margin-top :1vw;
  text-align: center;
}

.image img {
  max-width: 100%;
}

.answer-section input {
  padding: 2vw;
  margin-right: 10vw;
}

.navigation button, .answer-section button {
  margin: 1vw;
}

.logout-button {
  float: right;
  margin: 1vh !important;
}