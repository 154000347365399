.admin-body {
  min-height: 100vh;
}

.admin-header {
  display: flex; 
  justify-content: center; 
  align-items: center;
  padding-left: 0px !important;
}

.admin-sider {
  width: 14vw !important;
  max-width: 14vw !important;
  min-width: 14vw !important;
}

.admin-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vh;
}

.white-text-field {
  align-items: center;
  color: white !important;
  width: 10vw;
  margin-left: 1.5vw !important;
  margin-Top:1.5vw !important;
  margin-bottom: 1.5vw !important;
}

.white-text-field .MuiInputBase-input {
  color: white;
}

.white-text-field .MuiInputLabel-outlined {
  color: white;
}

.white-text-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.question {
  margin: 1vw 0;
  padding: 1vw;
  border: 3px solid #ccc;
  width: 80%;
  text-align: center;
}

.question-image-div{
  margin: 1vw 0;
  padding: 1vw;
  border: 3px solid #ccc;
  width: 80%;
  text-align: center;
  min-height: 60vh;
  max-width: 80vw;
}

.question-image{
  min-height: 60vh;
  max-width: 55vw;
}

.image img {
  max-width: 100%;
}

.navigation button {
  margin: 1vw;
}

.logout-button {
  float: right;
  margin: 1vw !important;
}