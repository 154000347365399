.home-body {
  background: linear-gradient(to right, rgba(106, 17, 203, 0.8), rgba(37, 117, 252, 0.8));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-button-group {
  margin-top: 2vh;
}