.captain-signup-body {
  /* fallback for old browsers */
  background: rgba(106, 17, 203, 0.8);

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 0.8), rgba(37, 117, 252, 0.8));

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, rgba(106, 17, 203, 0.8), rgba(37, 117, 252, 0.8));

  min-height: 100vh;
}