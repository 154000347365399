.not-found-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #519eeb;
  color: #333;
  font-family: Arial, sans-serif;
}

.not-found-container h1 {
  font-size: 10rem;
  margin: 0;
  color: #E74C3C; /* Red */
}

.not-found-container h2 {
  font-size: 3rem;
  margin: 1rem 0;
  color: #555;
}

.not-found-container p {
  font-size: 1.5rem;
  color: #4e4e4e;
}

.back-to-home {
  margin-top: 2rem;
  padding: 10px 20px;
  background-color: #34db74; /* Blue */
  color: #FFF;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-to-home:hover {
  background-color: #2980B9; /* Darker blue */
}